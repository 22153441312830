<template>
<div class="page">
	<SettingsHeader
	title="Attenders"
	@on-click-left="goOut">
		<template v-slot:left-button>
			<span class="iconify" 
				data-icon="material-symbols:arrow-left-alt-rounded" 
				data-width="21" 
				data-height="21">
			</span>
		</template>
	</SettingsHeader>

	<div class="attenders-menu">

		<!-- Empty message -->

		<div class="empty-message" v-if="attenders.length == 0 && !addFormIsOpen && isLoaded">
			<div class="empty-message__title">
				No attenders
			</div>
			<div class="empty-message__content">
				You can invite people who could be selected as attender of the event created in calendar
			</div>
		</div>

		<!-- Attenders list -->

		<div class="attenders-items" v-if="attenders.length > 0">
			<CollapseCard 
			v-for="a in attenders" 
			:key="`attender-${a.userId}`" 
			:title="a.fullName">

				<template v-slot:header-before>
					<div class="members-icon">
						<img :src="require(`@/assets/${a.isDisabled ? 
							'check-rounded-grey.svg':
							'check-rounded-blue.svg'}`)" 
						height="22" width="22"/> 	
					</div>
				</template>

				<template v-slot:header-after v-if="a.dependents && a.dependents.length">
					<div class="members-count">
						<b-tag type="is-info">{{ a.dependents.length }}</b-tag>
						<img :src="require('@/assets/family.svg')" height="18" width="18">
					</div>
				</template>

				<template v-slot v-if="a.dependents && a.dependents.length">
					<div class="members__title">
						Members
					</div>
					<div class="members__values">
						<div class="members__item" 
						v-for="m, index in a.dependents" 
						:key="`member-${m.userId}`">
							{{index + 1}}. {{ m.fullName }}
						</div>
					</div>
				</template>	

				<template v-slot:tools>
					<div class="collapse-buttons buttons reversed">
						<b-button type="is-info is-small is-light">Edit</b-button>
					</div>
				</template>	

			</CollapseCard>
		</div>

		<div class="buttons" v-if="!addFormIsOpen">
			<b-button type="is-info" @click="toggleForm" outlined expanded>Add</b-button>
		</div>

		<!-- Add form -->

		<div class="add-form" v-if="addFormIsOpen">
			<b-field label="First name" horizontal 
				:type="`${attenderNameError ? 
					'is-danger' : null}`"
				:message="attenderNameError ? 
					attenderNameError : ''"> 
				<b-input 
					placeholder="Person first name"
					@input="() => attenderNameError = null"
					v-model="attenderName">
				</b-input>
			</b-field>
			<b-field label="Last name (optional)" horizontal 
				:type="`${attenderFamilyError ? 
					'is-danger' : null}`"
				:message="attenderFamilyError ? 
					attenderFamilyError : ''"> 
				<b-input 
					placeholder="Person last name"
					@input="() => attenderFamilyError = null"
					v-model="attenderFamily">
				</b-input>
			</b-field>
			<b-checkbox type="is-info" 
				v-model="addDependedMembers" 
				@change="toggleAddMembers">
				Add depended family members
			</b-checkbox>
			<div class="add-form__depended-members-cont" v-if="addDependedMembers">
				<div class="add-form__member"
				v-for="_, index in dependedMembers" 
				:key="`member-${index}`">
					<div class="index-container">
						{{ index + 1 }}.
					</div>
					<b-field class="member-input"
						:type="`${dependedMembersErrors[index] ? 
							'is-danger' : null}`"
						:message="dependedMembersErrors[index] ? 
							dependedMembersErrors[index] : ''">
						<b-input expanded
							@input="() => dependedMembersErrors[index] = null"
							v-model="dependedMembers[index]" 
							placeholder="Family member name">
						</b-input>
					</b-field>
					<div class="remove-cont" @click="removeMember(index)">
						<img src="@/assets/close-rounded.svg" width="30">
					</div>
				</div>
				<div class="buttons reversed">
					<b-button type="is-info is-small" @click="addMember">Add</b-button>
				</div>
			</div>
			<div class="buttons">
				<b-button type="is-info" @click="addAttenderHandler">
					Save
				</b-button>
				<b-button type="is-info is-light" @click="toggleForm">
					Cancel
				</b-button>
			</div>
		</div>

	</div>
</div>
</template>

<script>
import CMS from '../../service/cms/service';
import CollapseCard from '../inputs/CollapseCard.vue';
import Attender from '../../scripts/attender.js';
import SettingsHeader from './SettingsHeader.vue';

export default {
	name: 'AttendersMenu',
	components: {
		CollapseCard,
		SettingsHeader
	},
	data() {
		return {
			attenderName: "",
			attenderNameError: null,
			attenderFamily: "",
			attenderFamilyError: null,
			addFormIsOpen: false,
			addDependedMembers: false,
			dependedMembers: [""],
			dependedMembersErrors: [null],
			attenders: [],
			isLoaded: false,
		};
	},

	created() {
		this.setupAttenderList()
	},

	beforeRouteLeave (to, from, next) {

		if (to.params.calendarUid != from.params.calendarUid) {
			this.$router.replace({
				name: 'calendar-schedule',
				params: {
					calendarUid: to.params.calendarUid,
				}
			})
		}

		next()
	},

	methods: {
		setupAttenderList(){
			let calendarUid = this.$route.params.calendarUid
			return CMS.attenders.list(calendarUid)
			.then(data => {
				if (data && data.err)
					throw data.err
			
				let attenders = []
				if (data.active) {
					data.active.forEach(attender => {
						attenders.push(new Attender(attender))
					})
				}
 				let isDisabled = true
				if (data.archive) {
					data.archive.forEach(attender => {
						attenders.push(new Attender(attender, isDisabled))
					})
				}
				this.attenders = attenders
			})
			.finally(() => {
				this.isLoaded = true
			})
		},
		toggleAddMembers(){
			if (!this.addDependedMembers) {
				this.dependedMembers = [""]
				this.dependedMembersErrors = [null]
				this.attenderName = ""
				this.attenderNameError = null
				this.attenderFamily = ""
			}
		},
		toggleForm(){
			if (this.addFormIsOpen){
				this.dependedMembers = [""]
				this.dependedMembersErrors = [null]
				this.attenderName = ""
				this.attenderFamily = ""
				this.attenderNameError = null
				this.addDependedMembers = false
			}
			this.addFormIsOpen = !this.addFormIsOpen
		},
		removeMember(index){
			if (this.dependedMembers.length == 1) {
				this.dependedMembers = [""]
				this.dependedMembersErrors = [null]
				this.addDependedMembers = false
			} else {
				this.dependedMembers = this.dependedMembers.filter((_, i) => i != index)
				this.dependedMembersErrors = this.dependedMembersErrors.filter((_, i) => i != index)
			}
		},
		addMember(){
			let members = [...this.dependedMembers, ""]
			this.dependedMembers = members
			let membersErrors = [...this.dependedMembersErrors, null]
			this.dependedMembersErrors = membersErrors
		},
		formValidate(){

			// Validate attenderName name
			if (this.attenderName == "") {
				this.attenderNameError = "Attender first name is required"
				return false
			}
			if (this.attenderName.length > 100) {
				this.attenderNameError = "Attender first name is too long"
				return false
			}

			// Validate attenderFamily name
			if (this.attenderFamily.length > 100) {
				this.attenderFamilyError = "Attender last name is too long"
				return false
			}
			
			if (!this.addDependedMembers)
				return true

			// Validate family members
			let errors = []
			this.dependedMembers.forEach((m, index) => {
				if (m == "")
					errors[index] = "Family member name is required" 
				if (m.length > 100)
					errors[index] = "Family member name is too long"
			})
			this.dependedMembersErrors = errors
			return errors.every(e => e == null)
		},
		addAttenderHandler(){
			if (!this.formValidate())
				return 
			let submitData = {
				firstName: this.attenderName,
				lastName: this.attenderFamily,
			}
			if (this.addDependedMembers)
				submitData["dependents"] = this.dependedMembers.map(m => ({firstName: m}))

			let calendarUid = this.$route.params.calendarUid

			CMS.attenders.create(calendarUid, submitData)
				.then(data => {
					if (data && data.err)
						throw data.err
					
					this.attenders.push(new Attender(data.attendee))

					this.toggleForm()
				})
		},
		goOut() {

			const originPage = JSON.parse(localStorage.getItem('originPage-attenders'))
			if (originPage) {
				localStorage.removeItem('originPage-attenders')
				this.$router.replace(originPage)
				this.$router.back()
				return ;
			}
			this.$router.push({
				name: 'calendar-schedule',
				params: {
					calendarUid: this.$route.params.calendarUid
				}
			})
        },
	},
};
</script>

<style scoped>
.page{
	width: 100%;
    min-height: 100vh;
    overflow: hidden;
}
.header {
    height: 32px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    padding: 2px 5px;

    background: lightgrey;
    position: fixed;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
	z-index: 2;
}

.button_go_back {
    display: flex;
    justify-content: center;
    align-items: center;
}
.field:not(:last-child){
	margin: 0;
}
.attenders-menu{
	margin-top: 32px;
    padding: 30px 10px 0 10px;
	display: flex;
	flex-direction: column;
	height: calc(100dvh - 32px);
	overflow-y: auto;
	gap: 16px;
}

.attenders-items{
	display: flex;
	flex-direction: column;
	gap: 4px;
	animation: fade .3s;
}

.empty-message > *:not(:last-child){
	margin-bottom: 16px;
}

.empty-message__title{
	font-size: 24px;
	font-weight: 600;
}

.add-form__member{
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-bottom: 16px;
	align-items: flex-start;
}

.index-container{
	width: 15px;
}

.add-form__member > *:not(.field){
	height: 40px;
	display: flex;
	align-items: center;
}

.remove-cont{
	height: 24px;
}

.buttons.reversed{
	display: flex;
	flex-direction: row-reverse;
	gap: 16px;
	width: 100%;
}

.add-form{
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.member-input{
	width: 100%;
}

.collapse-buttons{
	padding: 10px;
}

.members__title{
	font-weight: 600;
	margin-bottom: 8px;
}

.members__item{
	font-size: 14px;
}

.members-count{
	display: flex;
	width: 50px;
	gap: 10px;
}

.members-icon{
	display: flex;
	align-items: center;
}
</style>