var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('SettingsHeader',{attrs:{"title":"Attenders"},on:{"on-click-left":_vm.goOut},scopedSlots:_vm._u([{key:"left-button",fn:function(){return [_c('span',{staticClass:"iconify",attrs:{"data-icon":"material-symbols:arrow-left-alt-rounded","data-width":"21","data-height":"21"}})]},proxy:true}])}),_c('div',{staticClass:"attenders-menu"},[(_vm.attenders.length == 0 && !_vm.addFormIsOpen && _vm.isLoaded)?_c('div',{staticClass:"empty-message"},[_c('div',{staticClass:"empty-message__title"},[_vm._v(" No attenders ")]),_c('div',{staticClass:"empty-message__content"},[_vm._v(" You can invite people who could be selected as attender of the event created in calendar ")])]):_vm._e(),(_vm.attenders.length > 0)?_c('div',{staticClass:"attenders-items"},_vm._l((_vm.attenders),function(a){return _c('CollapseCard',{key:("attender-" + (a.userId)),attrs:{"title":a.fullName},scopedSlots:_vm._u([{key:"header-before",fn:function(){return [_c('div',{staticClass:"members-icon"},[_c('img',{attrs:{"src":require(("@/assets/" + (a.isDisabled ? 
							'check-rounded-grey.svg':
							'check-rounded-blue.svg'))),"height":"22","width":"22"}})])]},proxy:true},(a.dependents && a.dependents.length)?{key:"header-after",fn:function(){return [_c('div',{staticClass:"members-count"},[_c('b-tag',{attrs:{"type":"is-info"}},[_vm._v(_vm._s(a.dependents.length))]),_c('img',{attrs:{"src":require('@/assets/family.svg'),"height":"18","width":"18"}})],1)]},proxy:true}:null,(a.dependents && a.dependents.length)?{key:"default",fn:function(){return [_c('div',{staticClass:"members__title"},[_vm._v(" Members ")]),_c('div',{staticClass:"members__values"},_vm._l((a.dependents),function(m,index){return _c('div',{key:("member-" + (m.userId)),staticClass:"members__item"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(m.fullName)+" ")])}),0)]},proxy:true}:null,{key:"tools",fn:function(){return [_c('div',{staticClass:"collapse-buttons buttons reversed"},[_c('b-button',{attrs:{"type":"is-info is-small is-light"}},[_vm._v("Edit")])],1)]},proxy:true}],null,true)})}),1):_vm._e(),(!_vm.addFormIsOpen)?_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-info","outlined":"","expanded":""},on:{"click":_vm.toggleForm}},[_vm._v("Add")])],1):_vm._e(),(_vm.addFormIsOpen)?_c('div',{staticClass:"add-form"},[_c('b-field',{attrs:{"label":"First name","horizontal":"","type":("" + (_vm.attenderNameError ? 
					'is-danger' : null)),"message":_vm.attenderNameError ? 
					_vm.attenderNameError : ''}},[_c('b-input',{attrs:{"placeholder":"Person first name"},on:{"input":function () { return _vm.attenderNameError = null; }},model:{value:(_vm.attenderName),callback:function ($$v) {_vm.attenderName=$$v},expression:"attenderName"}})],1),_c('b-field',{attrs:{"label":"Last name (optional)","horizontal":"","type":("" + (_vm.attenderFamilyError ? 
					'is-danger' : null)),"message":_vm.attenderFamilyError ? 
					_vm.attenderFamilyError : ''}},[_c('b-input',{attrs:{"placeholder":"Person last name"},on:{"input":function () { return _vm.attenderFamilyError = null; }},model:{value:(_vm.attenderFamily),callback:function ($$v) {_vm.attenderFamily=$$v},expression:"attenderFamily"}})],1),_c('b-checkbox',{attrs:{"type":"is-info"},on:{"change":_vm.toggleAddMembers},model:{value:(_vm.addDependedMembers),callback:function ($$v) {_vm.addDependedMembers=$$v},expression:"addDependedMembers"}},[_vm._v(" Add depended family members ")]),(_vm.addDependedMembers)?_c('div',{staticClass:"add-form__depended-members-cont"},[_vm._l((_vm.dependedMembers),function(_,index){return _c('div',{key:("member-" + index),staticClass:"add-form__member"},[_c('div',{staticClass:"index-container"},[_vm._v(" "+_vm._s(index + 1)+". ")]),_c('b-field',{staticClass:"member-input",attrs:{"type":("" + (_vm.dependedMembersErrors[index] ? 
							'is-danger' : null)),"message":_vm.dependedMembersErrors[index] ? 
							_vm.dependedMembersErrors[index] : ''}},[_c('b-input',{attrs:{"expanded":"","placeholder":"Family member name"},on:{"input":function () { return _vm.dependedMembersErrors[index] = null; }},model:{value:(_vm.dependedMembers[index]),callback:function ($$v) {_vm.$set(_vm.dependedMembers, index, $$v)},expression:"dependedMembers[index]"}})],1),_c('div',{staticClass:"remove-cont",on:{"click":function($event){return _vm.removeMember(index)}}},[_c('img',{attrs:{"src":require("@/assets/close-rounded.svg"),"width":"30"}})])],1)}),_c('div',{staticClass:"buttons reversed"},[_c('b-button',{attrs:{"type":"is-info is-small"},on:{"click":_vm.addMember}},[_vm._v("Add")])],1)],2):_vm._e(),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-info"},on:{"click":_vm.addAttenderHandler}},[_vm._v(" Save ")]),_c('b-button',{attrs:{"type":"is-info is-light"},on:{"click":_vm.toggleForm}},[_vm._v(" Cancel ")])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }